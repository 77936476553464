import { makeAutoObservable } from "mobx";
import { TG_USER_ID } from "../constants/constant";

class PincodeStore {
  constructor() {
    makeAutoObservable(this);
  }

  setPincode = (pincode: string) => {
    localStorage.setItem(`pincode-${TG_USER_ID}`, pincode);
  };

  getPincode = () => {
    return localStorage.getItem(`pincode-${TG_USER_ID}`);
  };

  isPincodeRequired = () => {
    return sessionStorage.getItem(`needPincode-${TG_USER_ID}`);
  };

  clear = () => {
    localStorage.removeItem(`pincode-${TG_USER_ID}`);
    sessionStorage.removeItem(`needPincode-${TG_USER_ID}`);
  };
}

export const pincodeStore = new PincodeStore();
