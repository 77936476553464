import { makeAutoObservable } from "mobx";
import authApiClient from "../services/authApiClient";
import CryptoJS from "crypto-js";
import { TG_USER_ID } from "../constants/constant";

export interface IRegisterCheckResponse {
  result: boolean;
}

class AuthStore {
  phoneNumber = localStorage.getItem("phoneNumber");
  token = "";
  clientSalt = "";

  constructor() {
    makeAutoObservable(this);
  }

  registerCheck = async (phoneNumber: string) => {
    const currentTime = new Date().toISOString();
    const clientSalt = CryptoJS.MD5(currentTime).toString();

    this.clientSalt = clientSalt;

    try {
      const response = await authApiClient.post(
        "/auth/checkRegistered",
        {
          login: phoneNumber,
        },
        {
          headers: {
            "X-CLIENT-SALT": clientSalt,
          },
        }
      );

      localStorage.setItem(`clientSalt-${TG_USER_ID}`, clientSalt);
      localStorage.setItem("phoneNumber", phoneNumber);
      this.phoneNumber = phoneNumber;

      return response.data;
    } catch (error) {
      console.log("Error in registerCheck", error);
      return { result: false };
    }
  };

  register = async (password: string) => {
    try {
      const response = await authApiClient.post("/auth/register", {
        login: this.phoneNumber,
        password: password,
      });

      return response.data;
    } catch (error) {
      console.log("Error in register", error);
      return { result: false };
    }
  };

  registerConfirm = async (code: string) => {
    try {
      const response = await authApiClient.post("/auth/register/confirm", {
        login: this.phoneNumber,
        code: code,
      });

      return response.data;
    } catch (error) {
      console.log("Error in registerConfirm", error);
      return { result: false };
    }
  };

  login = async (password: string) => {
    try {
      const response = await authApiClient.post("/auth/login", {
        login: this.phoneNumber,
        password: password,
      });

      return response.data;
    } catch (error) {
      console.log("Error in login", error);
      return { result: false };
    }
  };

  loginConfirm = async (code: string) => {
    try {
      const response = await authApiClient.post("/auth/login/confirm", {
        login: this.phoneNumber,
        code: code,
      });

      localStorage.setItem(`token-${TG_USER_ID}`, response.data.result);

      this.token = response.data.result;

      return response.data;
    } catch (error) {
      console.log("Error in loginConfirm", error);
      return { result: false };
    }
  };
}

export const authStore = new AuthStore();
