import React from "react";
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
} from "react-router-dom";
import NotFound from "../pages/NotFound";
import Language from "../pages/Language";
import Auth from "../pages/Auth";
import ProtectedRoute from "./ProtectedRoute";
import Payment from "../pages/Payment";
import Layout from "./Layout";
import Chat from "../pages/Chat";
import History from "../pages/History";
import Profile from "../pages/Profile";
import Transfer from "../pages/Transfer";
import Notification from "../pages/Notification";
import Settings from "../pages/Settings";
import Ident from "../pages/Ident";
import ActiveDevices from "../pages/ActiveDevices";
import Monitoring from "../pages/Monitoring";
import AllCards from "../pages/AllCards";
import Login from "../pages/Auth/Login";
import Register from "../pages/Auth/Register";
import RegisterConfirm from "../pages/Auth/Register/RegisterConfirm";
import LoginConfirm from "../pages/Auth/Login/LoginConfirm";
import AllCategories from "../pages/Payment/AllCategories";
import PopularServices from "../pages/Payment/PopularServices";
import Destination from "../pages/Payment/Destination";
import HotNewsDetail from "../pages/Payment/HotNews/HotNewsDetail";
import AddCard from "../pages/AddCard";
import AddCardConfirm from "../pages/AddCard/AddCardConfirm";
import HistoryDetail from "../pages/History/HistoryDetail";
import TransferConfirm from "../pages/Transfer/TransferConfirm";
import Search from "../pages/Search";
import Story from "../pages/Story";
import PaymentService from "../pages/Payment/PaymentService";
import IdentCreate from "../pages/Ident/IdentCreate";
import IdentData from "../pages/Ident/IdentData";
import TransferConfirmSms from "../pages/Transfer/TransferConfirmSms";
import TransferResult from "../pages/Transfer/TransferResult";
import Point from "../pages/Point";
import Friend from "../pages/Point/Friend";
import Exchange from "../pages/Point/Exchange";
import ServiceConfirm from "../pages/Payment/PaymentService/ServiceConfirm";
import Deposit from "../pages/Point/Deposit";
import PointHistory from "../pages/Point/History";
import ServiceResult from "../pages/Payment/PaymentService/ServiceResult";
import ServiceConfirmSms from "../pages/Payment/PaymentService/ServiceConfirmSms";
import { storyStore } from "../stores/storyStore";
import { observer } from "mobx-react-lite";
import ExchangeConfirm from "../pages/Point/Exchange/ExchangeConfirm";
import ExchangeResult from "../pages/Point/Exchange/ExchangeResult";
import CreateVirtualCard from "../pages/Payment/CreateVirtualCard";
import ConfirmVirtualCard from "../pages/Payment/CreateVirtualCard/ConfirmVirtualCard";
import { TG_USER_ID } from "../constants/constant";
import AuthOffer from "../pages/Auth/AuthOffer";
import Pincode from "../pages/Pincode";

const Router: React.FC = observer(() => {
  const isAuthenticated = !!localStorage.getItem(`token-${TG_USER_ID}`);
  const hasSeenStory =
    storyStore.hasSeenStory || !!localStorage.getItem("hasSeenStory");

  const router = createBrowserRouter([
    {
      path: "/",
      element:
        (isAuthenticated && <Navigate to={"/payment"} />) ||
        (!hasSeenStory && <Navigate to={"/story"} />),
      children: [
        {
          index: true,
          element: <Language navigateTo="/auth" backTo="/" />,
        },
        {
          path: "/auth",
          element: <Auth />,
        },
        {
          path: "/auth/login",
          element: <Login />,
        },
        {
          path: "/auth/login/confirm",
          element: <LoginConfirm />,
        },
        {
          path: "/auth/offer",
          element: <AuthOffer />,
        },
        {
          path: "/auth/register",
          element: <Register />,
        },
        {
          path: "/auth/register/confirm",
          element: <RegisterConfirm />,
        },
      ],
    },
    {
      path: "/",
      element: <ProtectedRoute element={<Layout />} redirectTo="/" />,
      children: [
        {
          path: "/payment",
          element: <Payment />,
        },
        {
          path: "/transfer",
          element: <Transfer />,
        },
        {
          path: "/chat",
          element: <Chat />,
        },
        {
          path: "/point",
          element: <Point />,
        },
        {
          path: "/history",
          element: <History />,
        },
        {
          path: "/profile",
          element: <Profile />,
        },
      ],
    },
    {
      path: "/pincode",
      element: <Pincode />,
    },
    {
      path: "/payment",
      element: <ProtectedRoute element={<Outlet />} redirectTo="/" />,
      children: [
        {
          path: "/payment/notification",
          element: <Notification />,
        },
        {
          path: "/payment/search",
          element: <Search />,
        },
        {
          path: "/payment/allcards",
          element: <AllCards />,
        },
        {
          path: "/payment/addcard",
          element: <AddCard />,
        },
        {
          path: "/payment/addcard/confirm",
          element: <AddCardConfirm />,
        },
        {
          path: "/payment/allcategories",
          element: <AllCategories />,
        },
        {
          path: "/payment/popularservices",
          element: <PopularServices />,
        },
        {
          path: "/payment/destination/:id",
          element: <Destination />,
        },
        {
          path: "/payment/hotnews/:id",
          element: <HotNewsDetail />,
        },
        {
          path: "/payment/service/:id",
          element: <PaymentService />,
        },
        {
          path: "/payment/serviceConfirm/:id",
          element: <ServiceConfirm />,
        },
        {
          path: "/payment/service/result/:id",
          element: <ServiceResult />,
        },
        {
          path: "/payment/service/confirmSms/:id",
          element: <ServiceConfirmSms />,
        },
        {
          path: "/payment/createVirtualCard",
          element: <CreateVirtualCard />,
        },
        {
          path: "/payment/confirmVirtualCard",
          element: <ConfirmVirtualCard />,
        },
      ],
    },
    {
      path: "/transfer",
      element: <ProtectedRoute element={<Outlet />} redirectTo="/" />,
      children: [
        {
          path: "/transfer/confirm/:id",
          element: <TransferConfirm />,
        },
        {
          path: "/transfer/confirmSms/:id",
          element: <TransferConfirmSms />,
        },
        {
          path: "/transfer/result/:id",
          element: <TransferResult />,
        },
      ],
    },
    {
      path: "/point",
      element: <ProtectedRoute element={<Outlet />} redirectTo="/" />,
      children: [
        {
          path: "/point/friend",
          element: <Friend />,
        },
        {
          path: "/point/exchange",
          element: <Exchange />,
        },
        {
          path: "/point/exchange/confirm/:id",
          element: <ExchangeConfirm />,
        },
        {
          path: "/point/exchange/result/:id",
          element: <ExchangeResult />,
        },
        {
          path: "/point/deposit",
          element: <Deposit />,
        },
        {
          path: "/point/history",
          element: <PointHistory />,
        },
      ],
    },
    {
      path: "/history",
      element: <ProtectedRoute element={<Outlet />} redirectTo="/" />,
      children: [
        {
          path: "/history/:id",
          element: <HistoryDetail />,
        },
      ],
    },
    {
      path: "/profile",
      element: <ProtectedRoute element={<Outlet />} redirectTo="/" />,
      children: [
        {
          path: "/profile/settings",
          element: <Settings />,
        },
        {
          path: "/profile/ident",
          element: <Ident />,
        },
        {
          path: "/profile/ident/create",
          element: <IdentCreate />,
        },
        {
          path: "/profile/ident/data",
          element: <IdentData />,
        },
        {
          path: "/profile/activeDevices",
          element: <ActiveDevices />,
        },
        {
          path: "/profile/monitoring",
          element: <Monitoring />,
        },
        {
          path: "/profile/settings/language",
          element: (
            <Language
              navigateTo="/profile/settings"
              backTo="/profile/settings"
            />
          ),
        },
      ],
    },
    {
      path: "/story",
      element: hasSeenStory ? <Navigate to="/" /> : <Story />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);

  return <RouterProvider router={router} />;
});

export default Router;
