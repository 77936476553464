export const BASE_URL = "https://tgapi.1pay.uz"; //PROD
// export const BASE_URL = "https://tgapi.test.1pay.uz"; //TEST

export const BASE_API_URL = "https://telegappapi.pay-way.uz/api"; //PROD
// export const BASE_API_URL = "https://tgapi.test.1pay.uz/tgapi"; //TEST
// export const BASE_API_URL = "http://localhost:8090/api"; //DEV

export const TG_USER_ID = window.Telegram.WebApp.initDataUnsafe?.user?.id || 0;

export const TG_USER_LANG = window.Telegram.WebApp.initDataUnsafe?.user?.language_code;

export const CLIENT_SALT = localStorage.getItem(`clientSalt-${TG_USER_ID}`);

export const STATIC_URL = "https://static.1pay.uz";

export const TOKEN = localStorage.getItem(`token-${TG_USER_ID}`);

export const TG_PW_APP_URL = "https://t.me/PayWayApp_bot";

export const INIT_DATA = window.Telegram.WebApp.initData;
// export const INIT_DATA = "query_id=AAE8nr1cAAAAADyevVwB5i9l&user=%7B%22id%22%3A1555930684%2C%22first_name%22%3A%22%D0%90%D1%81%D1%80%D0%B0%D0%BD%D0%BE%D0%B2%20%D0%91%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22asrnvb%22%2C%22language_code%22%3A%22ru%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1728630489&hash=de1b478250d67c83f62071990daf52750412465871e00c6430d053a8ad4d2a02";
