import axios from "axios";
import { BASE_API_URL, CLIENT_SALT, INIT_DATA } from "../constants/constant";
import i18n from "../locales/i18n";
import { authStore } from "../stores/authStore";

const authApiClient = axios.create({
  baseURL: BASE_API_URL,
  timeout: 10000,
  headers: {
    initData: INIT_DATA,
    "Accept-Language": i18n.language,
    "Content-Type": "application/json",
  },
});

authApiClient.interceptors.request.use(
  (config) => {
    const clientSalt = authStore.clientSalt || CLIENT_SALT;

    config.headers["X-CLIENT-SALT"] = clientSalt;
    return config;
  },
  (error) => Promise.reject(error)
);

export default authApiClient;
