import React, { useEffect, useState } from "react";
import "./Pincode.css";
import { FiDelete } from "react-icons/fi";
import { pincodeStore } from "../../stores/pincodeStore";
import { useNavigate } from "react-router-dom";
import { TG_USER_ID } from "../../constants/constant";
import { t } from "i18next";

const hasPincode = localStorage.getItem(`pincode-${TG_USER_ID}`);

const Pincode: React.FC = () => {
  const [initPincode, setInitPincode] = useState("");
  const [pincode, setPincode] = useState("");
  const [incorrectPincode, setIncorrectPincode] = useState(false);
  const [pincodeMessage, setPincodeMessage] = useState("");
  const navigate = useNavigate();

  const handleNumberClick = (number: string) => {
    if (initPincode.length < 4 && !hasPincode) {
      setInitPincode(initPincode + number);
      return;
    }

    if (pincode.length < 4) {
      setPincode(pincode + number);
    }
  };

  const handleDelete = () => {
    if (initPincode.length > 0 && !hasPincode) {
      setInitPincode(initPincode.slice(0, -1));
      return;
    }
    
    setPincode(pincode.slice(0, -1));
  };

  useEffect(() => {
    if (
      initPincode.length === 4 &&
      pincode.length === 4 &&
      initPincode === pincode &&
      !hasPincode
    ) {
      pincodeStore.setPincode(pincode);
      sessionStorage.setItem(`pincode-${TG_USER_ID}`, pincode);

      navigate("/payment");

      return;
    }

    if (
      initPincode.length === 4 &&
      pincode.length === 4 &&
      initPincode !== pincode &&
      !hasPincode
    ) {
      setIncorrectPincode(true);

      setPincodeMessage(t("pincode.errorConfirmTitle"));

      setTimeout(() => {
        setIncorrectPincode(false);
        setPincode("");
      }, 1000);

      return;
    }

    if (pincode.length === 4 && pincode === pincodeStore.getPincode()) {
      sessionStorage.setItem(`pincode-${TG_USER_ID}`, pincode);

      navigate("/payment");
    }

    if (pincode.length === 4 && pincode !== pincodeStore.getPincode()) {
      setIncorrectPincode(true);

      setPincodeMessage(t("pincode.tryAgain"));

      setTimeout(() => {
        setIncorrectPincode(false);
        setPincode("");
      }, 1000);
    }
  }, [pincode, initPincode, navigate]);

  const pincodeTitle = () => {
    if (hasPincode) {
      return t("pincode.defaultTitle");
    } else if (!hasPincode && initPincode.length === 4) {
      return t("pincode.confirmTitle");
    } else {
      return t("pincode.createTitle");
    }
  };

  const pincodeDescription = () => {
    if (hasPincode) {
      return;
    } else if (!hasPincode && initPincode.length === 4) {
      return t("pincode.confirmDesc");
    } else {
      return t("pincode.createDesc");
    }
  };

  const pincodeValue = () => {
    if (hasPincode || initPincode.length === 4) {
      return pincode
        .padEnd(4, "•")
        .split("")
        .map((_, index) => (
          <span
            key={index}
            className={`pincode__char ${
              pincode[index] ? "pincode__char--filled" : ""
            }`}
          >
            •
          </span>
        ));
    } else {
      return initPincode
        .padEnd(4, "•")
        .split("")
        .map((_, index) => (
          <span
            key={index}
            className={`pincode__char ${
              initPincode[index] ? "pincode__char--filled" : ""
            }`}
          >
            •
          </span>
        ));
    }
  };

  return (
    <div className="pincode wrapper">
      <div className="pincode__display">
        <h2 className="pincode__title">{pincodeTitle()}</h2>
        <p className="pincode__description">{pincodeDescription()}</p>

        <p className="pincode__error">{pincodeMessage}</p>
        <h2
          className={`pincode__value ${
            incorrectPincode && "pincode__value-message"
          }`}
        >
          {pincodeValue()}
        </h2>
      </div>

      <div className="pincode__keyboard">
        <div className="pincode__row">
          <p className="pincode__number" onClick={() => handleNumberClick("1")}>
            1
          </p>
          <p className="pincode__number" onClick={() => handleNumberClick("2")}>
            2
          </p>
          <p className="pincode__number" onClick={() => handleNumberClick("3")}>
            3
          </p>
        </div>

        <div className="pincode__row">
          <p className="pincode__number" onClick={() => handleNumberClick("4")}>
            4
          </p>
          <p className="pincode__number" onClick={() => handleNumberClick("5")}>
            5
          </p>
          <p className="pincode__number" onClick={() => handleNumberClick("6")}>
            6
          </p>
        </div>

        <div className="pincode__row">
          <p className="pincode__number" onClick={() => handleNumberClick("7")}>
            7
          </p>
          <p className="pincode__number" onClick={() => handleNumberClick("8")}>
            8
          </p>
          <p className="pincode__number" onClick={() => handleNumberClick("9")}>
            9
          </p>
        </div>

        <div className="pincode__row">
          <p className="pincode__number pincode__empty"></p>
          <p className="pincode__number" onClick={() => handleNumberClick("0")}>
            0
          </p>
          <div className="pincode__number pincode__delete">
            <FiDelete onClick={handleDelete} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pincode;
