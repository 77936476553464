import React from "react";
import "./IdentData.css";
import SectionHeader from "../../../layouts/SectionHeader";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IAccountInfo } from "../../../models/IAccountInfo";
import DataField from "./DataField";

const IdentData: React.FC = () => {
  const location = useLocation();
  const accountInfo: IAccountInfo = JSON.parse(location.state.accountInfo);
  const { t } = useTranslation();

  const dataFields = [
    { label: t("dataIdent.surname"), value: accountInfo?.lastName },
    { label: t("dataIdent.name"), value: accountInfo?.fullName },
    { label: t("dataIdent.patronymic"), value: accountInfo?.middleName },
    { label: t("dataIdent.phone"), value: accountInfo?.phone },
    { label: t("dataIdent.dateBirth"), value: accountInfo?.birthDate },
    { label: t("dataIdent.country"), value: accountInfo?.country },
    { label: t("dataIdent.passportsn"), value: accountInfo?.passportSerialNumber },
    { label: t("dataIdent.date_of_issue"), value: accountInfo?.issuedDate },
  ];

  return (
    <>
      <SectionHeader />

      <div className="identData">
        <div className="identData__photo__wrapper">
          <img
            src={accountInfo?.photo || "https://via.placeholder.com/200"}
            alt="accountInfo__photo"
            className="identData__photo"
            loading="lazy"
          />
        </div>

        <div className="wrapper">
          {dataFields.map(
            (field, index) =>
              field.value && (
                <DataField
                  key={index}
                  label={field.label}
                  value={field.value}
                />
              )
          )}
        </div>
      </div>
    </>
  );
};

export default IdentData;
