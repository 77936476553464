import { makeAutoObservable } from "mobx";

class BalanceStore {
  showBalance = localStorage.getItem("showBalance") === "true";

  constructor() {
    makeAutoObservable(this);
  }

  toggleBalance = () => {
    this.showBalance = !this.showBalance;
    localStorage.setItem("showBalance", String(this.showBalance));
  };
}

export const balanceStore = new BalanceStore();
